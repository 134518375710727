import React, { useState, useEffect } from "react";
import app from "../firebase";
import { Button, ButtonGroup } from "react-bootstrap";
import AlarmClockCard from "../component/alarm/AlarmClockCard";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  getFirestore,
  doc,
  setDoc,
  orderBy,
  updateDoc,
  addDoc,
  Timestamp,
  onSnapshot,
  query,
  collection,
  where,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, uploadString, getDownloadURL } from "firebase/storage";
function AlarmActualList({
  // allAlarms,
  currentDayOfWeek,
  department,
  schoolId,
  handleViewTurnedActive,
  handleEditTimeChange,
  setAddShowModal,
  updateAlarmInFirestore,
  deleteAlarmInFirestore,
  createTextToSpeech,
}) {
  const db = getFirestore(app);

  // Define getSelectedDayObj before using it
  const getSelectedDayObj = (selectedDay) => {
    const selectedDayObj = new Date();
    let selectedDayNum;

    switch (selectedDay) {
      case "Mon":
        selectedDayNum = 1;
        break;
      case "Tue":
        selectedDayNum = 2;
        break;
      case "Wed":
        selectedDayNum = 3;
        break;
      case "Thu":
        selectedDayNum = 4;
        break;
      case "Fri":
        selectedDayNum = 5;
        break;
      case "Sat":
        selectedDayNum = 6;
        break;
      case "Sun":
        selectedDayNum = 0;
        break;
      default:
        selectedDayNum = -1;
    }
    selectedDayObj.setDate(selectedDayObj.getDate() + ((selectedDayNum - selectedDayObj.getDay() + 7) % 7));
    return selectedDayObj;
  };

  const [selectedDay, setSelectedDay] = React.useState(currentDayOfWeek);
  const [selectedDayDate, setSelectedDayDate] = React.useState(getSelectedDayObj(currentDayOfWeek));
  const [allAlarms, setAllAlarms] = useState([]);
  const [repeatAlarms, setRepeatAlarms] = useState([]);
  const [oneOffAlarms, setOneOffAlarms] = useState([]);

  useEffect(() => {
    // ANCHOR Get all alarms regardless of weekday
    const repeatAlarmQuery = query(
      collection(db, "alarms"),
      where("schoolId", "==", schoolId),
      where("department", "==", department),
      where("oneOff", "==", false)
      // orderBy("time", "asc")
    );

    const unsubscribeRepeatAlarm = onSnapshot(repeatAlarmQuery, (snapshot) => {
      const alarmsData = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setRepeatAlarms(alarmsData);
    });

    // get the start of week and end of week
    const startOfWeek = new Date();
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
    const endOfWeek = new Date();
    endOfWeek.setDate(endOfWeek.getDate() + (6 - endOfWeek.getDay()));
    // set time to 0:00 of start of week and 23:59 of end of week
    startOfWeek.setHours(0, 0, 0, 0);
    endOfWeek.setHours(23, 59, 59, 999);

    const oneOffAlarmQuery = query(
      collection(db, "alarms"),
      where("schoolId", "==", schoolId),
      where("department", "==", department),
      where("oneOff", "==", true),
      where("datetime", ">=", startOfWeek),
      where("datetime", "<=", endOfWeek),
      orderBy("datetime", "asc")
    );

    const unsubscribeOneOffAlarm = onSnapshot(oneOffAlarmQuery, (snapshot) => {
      const alarmsData = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setOneOffAlarms(alarmsData);
    });

    // Return a cleanup function to clear all timeouts and the Firestore listener
    return () => {
      unsubscribeRepeatAlarm();
      unsubscribeOneOffAlarm();
    };
  }, [db, schoolId, department]);

  // Combine both alarm types whenever either one changes
  useEffect(() => {
    const combinedAlarms = [...repeatAlarms, ...oneOffAlarms];

    // Sort by time, ascending
    combinedAlarms.sort((a, b) => {
      const aNumberTime = parseInt(a.time.split(":")[0]) * 60 + parseInt(a.time.split(":")[1]);
      const bNumberTime = parseInt(b.time.split(":")[0]) * 60 + parseInt(b.time.split(":")[1]);
      return aNumberTime - bNumberTime;
    });

    setAllAlarms(combinedAlarms);
  }, [repeatAlarms, oneOffAlarms]);

  const handleDayClick = (day) => {
    setSelectedDay(day);
    setSelectedDayDate(getSelectedDayObj(day));
  };

  return (
    <div>
      <Button type="button" className="btn btn-primary" onClick={() => setAddShowModal(true)}>
        新增鬧鐘
      </Button>
      <div>
        <ButtonGroup className="mb-3" aria-label="Days of the week">
          {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
            <Button
              key={day}
              variant={day === currentDayOfWeek ? "outline-primary" : "outline-secondary"}
              onClick={() => handleDayClick(day)}
              active={day === selectedDay}
            >
              {day} - {getSelectedDayObj(day)?.toLocaleDateString()}
            </Button>
          ))}
        </ButtonGroup>
      </div>
      <h1 className="mb-4">
        {schoolId} - {department} 今日鬧鐘 {selectedDay} {selectedDayDate.toLocaleDateString()}
      </h1>

      <div>
        {selectedDay !== "" && (
          <div>
            {allAlarms
              .filter((alarm) => {
                if (alarm.repeat && alarm.repeat.length > 0) {
                  return alarm.repeat?.includes(selectedDay);
                } else if (alarm.oneOff) {
                  let checkCondition = alarm.datetime.toDate().toDateString("en-US") == getSelectedDayObj(selectedDay).toDateString("");

                  return checkCondition;
                }
              })
              .map((alarm) => (
                <AlarmClockCard
                  key={alarm.id}
                  alarm={alarm}
                  selectedDay={selectedDay}
                  selectedDayDate={selectedDayDate}
                  handleViewTurnedActive={handleViewTurnedActive}
                  handleEditTimeChange={handleEditTimeChange}
                  handleSaveClick={updateAlarmInFirestore}
                  handleDeleteClick={deleteAlarmInFirestore}
                  createTextToSpeech={createTextToSpeech}
                />
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default AlarmActualList;

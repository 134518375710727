import React, { useState, useEffect } from "react";

import app from "../../firebase";
import { getFirestore, doc, setDoc, updateDoc, addDoc, Timestamp, onSnapshot, query, collection, where } from "firebase/firestore";
import { Modal, Button, Form, FormGroup, FormLabel, FormControl, ButtonGroup } from "react-bootstrap";

function AlarmClockDisplay({ alarm, selectedDayDate, isEditing, handleEditClick, createTextToSpeech }) {
  const [audioSrc, setAudioSrc] = useState(alarm.audioUrl);
  const [isPlaying, setIsPlaying] = useState(false);
  const isSnoozeAlarm = alarm.title && alarm.title.includes("貪睡");

  function playAudio(url) {
    setAudioSrc(url);
    setIsPlaying(true);
  }

  function handleAudioEnd() {
    setIsPlaying(false);
  }

  // Render a compact version for snooze alarms
  if (isSnoozeAlarm) {
    return (
      <div className="card mb-2 shadow-sm border-0 rounded-3 hover-shadow">
        <div className="card-body py-2 px-3">
          <div className="ms-2">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <h5 className="card-title m-0 text-muted">貪睡 {alarm.time}</h5>

                {/* <h5 className="card-title m-0 text-muted">貪睡 {alarm.time}</h5> */}
                <span className="ms-2 text-muted small">{alarm.title}</span>
              </div>
              <div className="d-flex">
                <button className="btn btn-sm btn-link text-muted p-1" onClick={() => playAudio(alarm.audioUrl)}>
                  <i className="bi bi-play-circle"></i>
                </button>
                <button className="btn btn-sm btn-link text-muted p-1" type="button" onClick={handleEditClick}>
                  <i className="bi bi-pencil"></i>
                </button>
              </div>
            </div>
            {alarm.doneAt ? (
              alarm.doneAt.toDate().toDateString() === selectedDayDate.toDateString() ? (
                <span className="badge bg-success">✅ {alarm.doneAt.toDate().toLocaleTimeString()}</span>
              ) : (
                <span className="badge bg-secondary">
                  <i className="bi bi-square"></i> 未打勾
                </span>
              )
            ) : (
              <span className="badge bg-secondary">
                <i className="bi bi-square"></i> 未打勾
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }

  // Regular alarm display (unchanged)
  return (
    <div className="card mb-3 shadow-sm border-0 rounded-3 hover-shadow">
      <div className="card-body p-4">
        {/* Main information section - time, title, description */}
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3 className="card-title m-0 fw-bold text-primary">{alarm.time}</h3>
          <h4 className="card-subtitle text-secondary">{alarm.title}</h4>
        </div>

        <div className="d-flex flex-row mb-3 align-items-center">
          <p className="card-text flex-fill mb-0">{alarm.description}</p>

          {/* Audio controls in a more compact format */}
          <div className="ms-3">
            {alarm.audioUrl ? (
              <div className="btn-group">
                <audio src={audioSrc} controls className="me-2" style={{ height: "30px" }} onEnded={handleAudioEnd} />
                <button className="btn btn-sm btn-outline-primary" onClick={() => createTextToSpeech(alarm.description, alarm)}>
                  <i className="bi bi-mic-fill"></i>
                </button>
              </div>
            ) : (
              <button className="btn btn-sm btn-primary" onClick={() => createTextToSpeech(alarm.description, alarm)}>
                <i className="bi bi-mic-fill"></i> 存音檔
              </button>
            )}
          </div>
        </div>
        {alarm.doneAt ? (
          alarm.doneAt.toDate().toDateString() === selectedDayDate.toDateString() ? (
            <span className="badge bg-success">當天✅ {alarm.doneAt.toDate().toLocaleTimeString()}</span>
          ) : (
            <span className="badge bg-secondary">
              <i className="bi bi-square"></i> 未打勾
            </span>
          )
        ) : (
          <span className="badge bg-secondary">
            <i className="bi bi-square"></i> 未打勾
          </span>
        )}
        {/* Secondary information in a collapsible section */}
        <div className="d-flex justify-content-between align-items-center">
          <div className="small text-muted">
            {alarm.oneOff ? (
              <span>
                <i className="bi bi-calendar-event me-1"></i>一次性: {alarm.datetime?.toDate().toLocaleDateString()}
              </span>
            ) : (
              <span>
                <i className="bi bi-arrow-repeat me-1"></i>
                {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].filter((day) => alarm.repeat?.includes(day)).join(", ")}
              </span>
            )}
          </div>

          <button className="btn btn-sm btn-outline-primary rounded-pill" type="button" onClick={handleEditClick}>
            <i className="bi bi-pencil me-1"></i>編輯
          </button>
        </div>
      </div>
    </div>
  );
}

function AlarmClockEdit({ alarm, isEditing, handleEditClick, handleSaveClick, handleDeleteClick, createTextToSpeech }) {
  const [editedAlarm, setEditedAlarm] = useState({ ...alarm, active: alarm.active !== false });
  const [isGenerating, setIsGenerating] = useState(false);

  function handleInputChange(event, curDay) {
    let { name, value } = event.target;

    if (name === "active") {
      setEditedAlarm({ ...editedAlarm, active: !editedAlarm.active });
    }
    if (name.includes("repeat")) {
      let newRepeat = [...editedAlarm.repeat];
      if (newRepeat.includes(curDay)) {
        newRepeat = newRepeat.filter((day) => day !== curDay);
      } else {
        newRepeat.push(curDay);
      }

      setEditedAlarm({ ...editedAlarm, repeat: newRepeat });
    } else {
      setEditedAlarm({ ...editedAlarm, [name]: value });
    }
  }

  const sendTextToChatGPTForPoliteResponse = async (userText) => {
    setIsGenerating(true);
    try {
      const response = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer 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`,
        },
        body: JSON.stringify({
          model: "gpt-4",
          messages: [
            {
              role: "system",
              content:
                "Imagine you are a benovolent boss, with a less formal more friendly way of communication. Rewrite these commands into very kind and polite commands. Keep it within two or three sentences. Use traditional chinese unless the phrase in mentioned in english.",
            },
            { role: "user", content: "3:00 檢查及關閉所有電源" },
            {
              role: "assistant",
              content:
                "三點鐘嘍！大家辛苦了！在離開之前，能麻煩大家檢查一下所有的電源，確保它們都已經關閉嗎？這樣能幫我們節省電費和保護環境哦！謝謝大家！",
            },
            { role: "user", content: "17:15 下課時段站門口注意家長學生是否有找到家長再出去，學生要講May I sign out?" },
            {
              role: "assistant",
              content:
                "五點十五嘍！學生下課的時候，請去門口看看有沒有學生和家長一起出去哦！學生離開前，請記得跟老師講一聲 'May I sign out?'！謝謝大家一起維護學生的安全！",
            },

            {
              role: "user",
              content: `${userText}`,
            },
          ],
        }),
      });
      const data = await response.json();
      console.log(data.choices[0].message.content, "content");
      setEditedAlarm({ ...editedAlarm, description: data.choices[0].message.content });
    } catch (err) {
      console.log("Error:", err);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div className="card mb-3 shadow border-0 rounded-3">
      <div className="card-body p-4">
        <div className="mb-4">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <label className="form-label fw-bold">時間</label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="active"
                id="activeSwitch"
                checked={editedAlarm.active}
                onChange={(e) => handleInputChange(e)}
              />
              <label className="form-check-label" htmlFor="activeSwitch">
                {editedAlarm.active ? "啟用" : "停用"}
              </label>
            </div>
          </div>
          <input
            type="time"
            name="time"
            className="form-control form-control-lg"
            value={editedAlarm.time}
            onChange={(e) => handleInputChange(e)}
          />
        </div>

        <div className="mb-3">
          <label className="form-label fw-bold">標題</label>
          <input
            type="text"
            name="title"
            className="form-control"
            value={editedAlarm.title}
            onChange={(e) => handleInputChange(e)}
            placeholder="輸入鬧鐘標題"
          />
        </div>

        <div className="mb-4">
          <label className="form-label fw-bold">語音朗讀</label>
          <div className="input-group">
            <input
              type="text"
              name="description"
              className="form-control"
              value={editedAlarm.description}
              onChange={(e) => handleInputChange(e)}
              placeholder="輸入要朗讀的文字"
            />
            <button
              className="btn btn-pink"
              style={{ backgroundColor: "pink", color: "#000000" }}
              onClick={() => sendTextToChatGPTForPoliteResponse(`${editedAlarm.time} ${editedAlarm.description}`)}
              disabled={isGenerating}
            >
              {isGenerating ? (
                <>
                  <span className="spinner-border spinner-border-sm me-1"></span> 生成中...
                </>
              ) : (
                <>
                  <i className="bi bi-magic me-1"></i> AI改寫
                </>
              )}
            </button>
          </div>
        </div>

        {alarm.oneOff === true ? (
          <div className="alert alert-info py-2 mb-3">
            <i className="bi bi-calendar-event me-2"></i>一次性的日期: {alarm.datetime?.toDate().toDateString()}
          </div>
        ) : (
          <div className="mb-4">
            <label className="form-label fw-bold">
              <i className="bi bi-arrow-repeat me-1"></i>週期性:
            </label>
            <div className="d-flex flex-wrap gap-2 mt-2">
              {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day) => (
                <button
                  key={day}
                  type="button"
                  className={`btn ${editedAlarm.repeat?.includes(day) ? "btn-primary" : "btn-outline-secondary"}`}
                  onClick={(e) => handleInputChange({ target: { name: "repeat" + day } }, day)}
                >
                  {day}
                </button>
              ))}
            </div>
          </div>
        )}

        <div className="d-flex gap-2 mt-4">
          <button className="btn btn-success rounded-pill" type="button" onClick={() => handleSaveClick(editedAlarm)}>
            <i className="bi bi-check-circle me-1"></i> 儲存編輯
          </button>

          <button className="btn btn-outline-secondary rounded-pill" type="button" onClick={handleEditClick}>
            <i className="bi bi-x-circle me-1"></i> 取消編輯
          </button>

          <button
            className="btn btn-outline-danger ms-auto rounded-pill"
            type="button"
            onClick={() => {
              const confirmResult = window.confirm("確定要刪除這個鬧鐘嗎？");
              if (confirmResult) {
                handleDeleteClick(alarm);
              }
            }}
          >
            <i className="bi bi-trash me-1"></i> 刪除鬧鐘
          </button>
        </div>
      </div>
    </div>
  );
}

function AlarmClockCard({
  alarm,
  selectedDayDate,
  handleViewTurnedActive,
  handleEditTimeChange,
  handleSaveClick,
  handleDeleteClick,
  createTextToSpeech,
}) {
  const [showDetails, setShowDetails] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  function toggleDetails() {
    setShowDetails(!showDetails);
  }

  function handleEditClick() {
    setIsEditing((prev) => !prev);
  }

  if (isEditing) {
    return (
      <>
        <AlarmClockEdit
          alarm={alarm}
          handleEditTimeChange={handleEditTimeChange}
          handleViewTurnedActive={handleViewTurnedActive}
          toggleDetails={toggleDetails}
          showDetails={showDetails}
          handleSaveClick={(alarm) => {
            handleSaveClick(alarm);
            setIsEditing(false);
          }}
          isEditing={isEditing}
          handleEditClick={handleEditClick}
          handleDeleteClick={handleDeleteClick}
          createTextToSpeech={createTextToSpeech}
        />
      </>
    );
  } else {
    return (
      <>
        <AlarmClockDisplay
          alarm={alarm}
          selectedDayDate={selectedDayDate}
          handleViewTurnedActive={handleViewTurnedActive}
          toggleDetails={toggleDetails}
          showDetails={showDetails}
          isEditing={isEditing}
          handleEditClick={handleEditClick}
          createTextToSpeech={createTextToSpeech}
        ></AlarmClockDisplay>
      </>
    );
  }
}

export default AlarmClockCard;

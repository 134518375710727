import React, { useState, useEffect, useRef, useMemo } from "react";
import AlarmClockDetails from "../component/AlarmClockDetails";
import soundFile from "../asset/alarm-clock-short.mp3";
import AddAlarmModal from "../component/alarm/AddAlarmModal";
import AlarmClockCard from "../component/alarm/AlarmClockCard";

import app from "../firebase";
import {
  getFirestore,
  doc,
  setDoc,
  orderBy,
  updateDoc,
  addDoc,
  Timestamp,
  onSnapshot,
  query,
  collection,
  where,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";
import { Modal, Button, Form, FormGroup, FormLabel, FormControl, ButtonGroup } from "react-bootstrap";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage, ref, uploadBytes, uploadString, getDownloadURL } from "firebase/storage";
import AlarmActualList from "./AlarmActualList";
import ECBList from "./ECBList";
import NextWeekAction from "./NextWeekAction";
import CheckAttendance from "./CheckAttendance";
import CheckAttendanceDay from "./CheckAttendanceDay";
import "bootstrap-icons/font/bootstrap-icons.css";
// I need to know the test situation.
function AlarmClockModal({
  alarms,
  setAlarms,
  showModal,
  schoolUsers,
  handleSnoozeClick,
  handleSetToDoneClick,
  handleClose,
  createTextToSpeech,
}) {
  const [isAlarmPlaying, setIsAlarmPlaying] = useState(false);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [lastPlayedTime, setLastPlayedTime] = useState({});
  const playbackInitializedRef = useRef(false);
  const [currentPlayingAlarmId, setCurrentPlayingAlarmId] = useState(null);

  // Deduplicate alarms based on title or description
  const uniqueAlarms = useMemo(() => {
    const seen = new Map();
    return alarms.filter((alarm) => {
      // Use title as the key for deduplication, fallback to description if no title
      const key = alarm.title || alarm.description;
      if (seen.has(key)) {
        return false;
      }
      seen.set(key, true);
      return true;
    });
  }, [alarms]);

  const stopAlarms = () => {
    setIsAlarmPlaying(false);
    if (currentAudio) {
      currentAudio.pause();
      setCurrentAudio(null);
    }
    setCurrentPlayingAlarmId(null);
  };

  const playNextAlarm = async () => {
    if (isAlarmPlaying) return;

    if (uniqueAlarms.length === 0) {
      stopAlarms();
      return;
    }

    // Find the next alarm to play (prioritize alarms that haven't played recently)
    const now = Date.now();
    const nextAlarmToPlay = uniqueAlarms.reduce((best, current) => {
      const lastPlayed = lastPlayedTime[current.id] || 0;
      const bestLastPlayed = lastPlayedTime[best?.id] || 0;

      if (!best || lastPlayed < bestLastPlayed) {
        return current;
      }
      return best;
    }, null);

    if (!nextAlarmToPlay) return;

    // Mark as playing and update last played time
    setIsAlarmPlaying(true);
    setLastPlayedTime((prev) => ({
      ...prev,
      [nextAlarmToPlay.id]: now,
    }));
    // Set the currently playing alarm ID
    setCurrentPlayingAlarmId(nextAlarmToPlay.id);

    try {
      console.log("Playing alarm", nextAlarmToPlay.id, nextAlarmToPlay.title);

      if (nextAlarmToPlay.audioUrl) {
        const audio = new Audio(nextAlarmToPlay.audioUrl);
        setCurrentAudio(audio);

        await new Promise((resolve) => {
          audio.play();
          audio.addEventListener("ended", () => {
            setTimeout(resolve, 1000);
          });
        });
      } else {
        await createTextToSpeech(nextAlarmToPlay.description, nextAlarmToPlay);
        await new Promise((resolve) => setTimeout(resolve, 3000)); // Wait for TTS to complete
      }
    } catch (error) {
      console.error("Error playing alarm:", error);
    } finally {
      setIsAlarmPlaying(false);
      setCurrentAudio(null);
      setCurrentPlayingAlarmId(null);
    }
  };

  // Update the useEffect to use uniqueAlarms
  useEffect(() => {
    if (uniqueAlarms.length === 0) {
      handleClose();
      stopAlarms();
      playbackInitializedRef.current = false;
      return;
    }

    if (!playbackInitializedRef.current && !isAlarmPlaying) {
      playbackInitializedRef.current = true;
      playNextAlarm();
    }

    // Change from 15000 (15 seconds) to 30000 (30 seconds)
    const intervalId = setInterval(() => {
      if (!isAlarmPlaying && uniqueAlarms.length > 0) {
        playNextAlarm();
      }
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, [uniqueAlarms, isAlarmPlaying]);

  const handleSnooze = (alarm, time = 5, alarmTitle) => {
    console.log("znooe,", alarmTitle);

    handleSnoozeClick(alarm, time, alarmTitle);

    // Filter out the snoozed alarm from the alarms array
    const filteredAlarms = alarms.filter((a) => a.id !== alarm.id);
    setAlarms(filteredAlarms);
    console.log("filteredAlarms", filteredAlarms);

    // If it's the last alarm, stop all audio
    if (filteredAlarms.length === 0) {
      stopAlarms();
      handleClose();
    }
  };

  const handleSetToDone = (alarm) => {
    handleSetToDoneClick(alarm);

    // Filter out the completed alarm from the alarms array
    const filteredAlarms = alarms.filter((a) => a.id !== alarm.id);
    setAlarms(filteredAlarms);

    // If it's the last alarm, stop all audio and close the modal
    if (filteredAlarms.length === 0) {
      stopAlarms();
      handleClose();
    }
  };
  const [customSnoozeTime, setCustomSnoozeTime] = useState("");

  return (
    <Modal show={showModal} centered size="lg">
      <Modal.Header className="bg-warning bg-opacity-10">
        <Modal.Title>
          <i className="bi bi-alarm me-2"></i>
          提醒您！
        </Modal.Title>
        <button type="button" className="btn-close" onClick={handleClose}></button>
      </Modal.Header>
      <Modal.Body className="p-4">
        {uniqueAlarms.map(
          (alarm) =>
            alarm.active && (
              <div
                key={alarm.id}
                className={`card mb-3 border-0 shadow-sm ${alarm.id === currentPlayingAlarmId ? "playing-alarm" : ""}`}
                style={{
                  transition: "all 0.5s ease",
                  background:
                    alarm.id === currentPlayingAlarmId
                      ? "linear-gradient(90deg, rgba(255,236,170,0.5) 0%, rgba(255,224,130,0.5) 100%)"
                      : "white",
                  boxShadow: alarm.id === currentPlayingAlarmId ? "0 0 15px rgba(255, 193, 7, 0.5)" : "",
                }}
              >
                <div className="card-body">
                  <h4 className="card-title mb-3">{alarm.title}</h4>
                  <p className="card-title mb-3 small">
                    {alarm.time} - {alarm.description}
                  </p>

                  {schoolUsers?.length > 0 && (
                    <div className="mb-3">
                      <p className="text-muted small mb-2">標記為完成：</p>
                      <div className="d-flex flex-wrap gap-2" style={{ justifyContent: "space-between" }}>
                        {schoolUsers?.map((user) => (
                          <Button
                            key={user.id}
                            variant="primary"
                            size="sm"
                            onClick={() => handleSetToDone(alarm)}
                            style={{ minWidth: "100px", flex: "1 0 auto", maxWidth: "150px" }}
                          >
                            <p className="text-center" style={{ fontSize: user.displayName.length > 12 ? "0.8em" : "1em" }}>
                              {user.displayName}
                            </p>
                          </Button>
                        ))}
                      </div>
                    </div>
                  )}

                  <div className="mt-3">
                    <p className="text-muted small mb-2">貪睡選項：</p>
                    <div className="d-flex flex-wrap gap-2">
                      <Button variant="outline-secondary" size="sm" onClick={() => handleSnooze(alarm, 1, alarm.title)}>
                        <i className="bi bi-alarm me-1"></i>
                        1分鐘
                      </Button>

                      <Button variant="outline-secondary" size="sm" onClick={() => handleSnooze(alarm, 5, alarm.title)}>
                        <i className="bi bi-alarm me-1"></i>
                        5分鐘
                      </Button>

                      <Button variant="outline-secondary" size="sm" onClick={() => handleSnooze(alarm, 15, alarm.title)}>
                        <i className="bi bi-alarm me-1"></i>
                        15分鐘
                      </Button>

                      <Button
                        variant="outline-secondary"
                        size="sm"
                        onClick={() => {
                          const snoozeTime = prompt("請輸入貪睡時間(分鐘):");
                          if (snoozeTime) {
                            handleSnooze(alarm, parseInt(snoozeTime), alarm.title);
                          }
                        }}
                      >
                        <i className="bi bi-pencil-square me-1"></i>
                        自訂
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )
        )}
      </Modal.Body>
    </Modal>
  );
}

function AlarmClockList({ schoolId, department }) {
  // const department = "櫃檯";
  //   const [timeouts, setTimeouts] = useState([]);
  const [activeAlarms, setActiveAlarms] = useState([]);
  const functions = getFunctions(app);
  const storage = getStorage(app);
  const textToSpeech = httpsCallable(functions, "textToSpeech");

  const [showAddModal, setAddShowModal] = useState(false);

  const [showAlertModal, setShowAlertModal] = useState(false);
  const db = getFirestore(app);
  const [todayAlarms, setTodayAlarms] = useState([
    {
      id: 1,
      time: "06:00",
      description: "Wake up early",
      active: false,
      repeat: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    },
  ]);

  // const [allAlarms, setAllAlarms] = useState([]);
  const [schoolUsers, setSchoolUsers] = useState([]);

  const currentDayOfWeek = new Date().toLocaleString("en-US", { weekday: "short" }).substring(0, 3);

  const [selectedDay, setSelectedDay] = useState(currentDayOfWeek);

  const [currentMode, setCurrentMode] = useState("鬧鐘");
  function handleDayClick(day) {
    setSelectedDay(day);
  }

  function promptForDeviceID() {
    let deviceID = localStorage.getItem("deviceID");

    if (!deviceID) {
      // Prompt the user to enter a device ID
      deviceID = window.prompt("Please enter a unique device ID:");
      localStorage.setItem("deviceID", deviceID);
    }

    return deviceID;
  }

  useEffect(() => {
    // Reset the lastSeen whenever the alarms all changed
    const deviceID = promptForDeviceID();
    const userId = getAuth(app).currentUser.uid;
    const userName = getAuth(app).currentUser.email;
    const docRef = doc(db, `alarmDevice/${userName}/device`, deviceID);
    getDoc(docRef).then((doc) => {
      if (!doc.exists()) {
        console.log("No such document!");
      } else {
        console.log("Document data:", doc.data());
      }
    });

    setDoc(docRef, { lastSeen: Timestamp.now(), deviceID: deviceID, userId: userId, name: userName }, { merge: true });
  }, []);

  useEffect(() => {
    {
      // Set up a listener for the 'alarms' collection
      const q = query(collection(db, "users"), where(`schools.${schoolId}`, "==", true));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        console.log(snapshot.docs, "alarms Accountable");
        const schoolsUser = snapshot.docs
          .filter((doc) => doc.data().alarmAccountable?.[schoolId] == true)
          .map((doc) => ({ ...doc.data(), id: doc.id }));

        setSchoolUsers(schoolsUser);
      });

      // Return a cleanup function to clear all timeouts and the Firestore listener
      return () => {
        unsubscribe();
      };
    }
  }, []);

  useEffect(() => {
    // 1. Fetch recurring alarms for today
    const currentDayOfWeek = new Date().toLocaleString("en-US", { weekday: "short" }).substring(0, 3);
    const qRepeat = query(
      collection(db, "alarms"),
      where("schoolId", "==", schoolId),
      where("department", "==", department),
      where("repeat", "array-contains", currentDayOfWeek),
      where("oneOff", "==", false),
      orderBy("time", "asc")
    );

    const unsubRepeat = onSnapshot(qRepeat, (snapshot) => {
      const recurringData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      mergeTodayAlarms(recurringData);
    });

    // 2. Fetch one-off alarms that fall on today's date
    const startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date();
    endOfDay.setHours(23, 59, 59, 999);

    const qOneOff = query(
      collection(db, "alarms"),
      where("schoolId", "==", schoolId),
      where("department", "==", department),
      where("oneOff", "==", true),
      where("datetime", ">=", startOfDay),
      where("datetime", "<=", endOfDay),
      orderBy("datetime", "asc")
    );

    const unsubOneOff = onSnapshot(qOneOff, (snapshot) => {
      const oneOffData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      mergeTodayAlarms(oneOffData);
    });

    // Cleanup
    return () => {
      unsubRepeat();
      unsubOneOff();
    };
  }, [schoolId, department]);

  // Merges new items into the `todayAlarms` state, sorting by time
  function mergeTodayAlarms(newArr) {
    setTodayAlarms((prev) => {
      // Remove duplicates by ID
      const existing = [...prev];
      newArr.forEach((a) => {
        const idx = existing.findIndex((x) => x.id === a.id);
        if (idx >= 0) {
          existing[idx] = a; // update
        } else {
          existing.push(a);
        }
      });
      // Sort by time
      existing.sort((a, b) => {
        const [ah, am] = a.time.split(":").map(Number);
        const [bh, bm] = b.time.split(":").map(Number);
        return ah * 60 + am - (bh * 60 + bm);
      });
      return existing;
    });
  }

  // Poll every minute (or every 10s) to see if any alarm time == now
  useEffect(() => {
    const intervalId = setInterval(() => {
      checkForActiveAlarms();
    }, 10000); // every 10 seconds or 60,000 for every minute

    // Check once on mount
    checkForActiveAlarms();

    return () => clearInterval(intervalId);
  }, [todayAlarms]);

  function checkForActiveAlarms() {
    const now = new Date();
    const hhmm = `${String(now.getHours()).padStart(2, "0")}:${String(now.getMinutes()).padStart(2, "0")}`;

    // Create start and end of today in UTC+8
    const startOfDayUTC8 = new Date();
    startOfDayUTC8.setHours(0, 0, 0, 0);

    const endOfDayUTC8 = new Date();
    endOfDayUTC8.setHours(23, 59, 59, 999);

    // find alarms that match current time & are active
    const newlyTriggered = todayAlarms.filter((alarm) => {
      // Check if time matches and alarm is active
      if (alarm.time !== hhmm || !alarm.active) return false;

      // If there's no doneAt, it should trigger
      if (!alarm.doneAt) return true;

      // Convert doneAt Firestore timestamp to JS Date
      const doneAtDate = alarm.doneAt.toDate ? alarm.doneAt.toDate() : new Date(alarm.doneAt);

      // Check if doneAt is NOT from today (outside the range of today)
      return doneAtDate < startOfDayUTC8 || doneAtDate > endOfDayUTC8;
    });

    // For each newly triggered, if not in activeAlarms, add it
    let alarmsToAdd = [];
    newlyTriggered.forEach((alarm) => {
      if (!activeAlarms.some((act) => act.id === alarm.id)) {
        alarmsToAdd.push(alarm);
      }
    });

    if (alarmsToAdd.length > 0) {
      setActiveAlarms((prev) => [...prev, ...alarmsToAdd]);
      setShowAlertModal(true);
    }
  }

  // useEffect(() => {
  //   // ANCHOR Get all alarms regardless of weekday
  //   const repeatAlarmQuery = query(
  //     collection(db, "alarms"),
  //     where("schoolId", "==", schoolId),
  //     where("department", "==", department),
  //     where("oneOff", "==", false)
  //     // orderBy("time", "asc")
  //   );

  //   const unsubscribeRepeatAlarm = onSnapshot(repeatAlarmQuery, (snapshot) => {
  //     const alarmsData = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  //     setAllAlarms((prevAlarms) => {
  //       const updatedAlarms = prevAlarms.filter((alarm) => alarm.oneOff);

  //       let allAlarms = [...updatedAlarms, ...alarmsData];
  //       // sort by time, ascending
  //       console.log(allAlarms, "repeatedAlarms");
  //       allAlarms.sort((a, b) => {
  //         a.numberTime = parseInt(a.time.split(":")[0]) * 60 + parseInt(a.time.split(":")[1]);
  //         b.numberTime = parseInt(b.time.split(":")[0]) * 60 + parseInt(b.time.split(":")[1]);

  //         return a.numberTime - b.numberTime;
  //       });
  //       return allAlarms;
  //     });
  //   });

  //   // get the start of week and end of week
  //   const startOfWeek = new Date();
  //   startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
  //   const endOfWeek = new Date();
  //   endOfWeek.setDate(endOfWeek.getDate() + (6 - endOfWeek.getDay()));
  //   // set time to 0:00 of start of week and 23:59 of end of week
  //   startOfWeek.setHours(0, 0, 0, 0);
  //   endOfWeek.setHours(23, 59, 59, 999);

  //   const oneOffAlarmQuery = query(
  //     collection(db, "alarms"),
  //     where("schoolId", "==", schoolId),
  //     where("department", "==", department),
  //     where("oneOff", "==", true),
  //     where("datetime", ">=", startOfWeek),
  //     where("datetime", "<=", endOfWeek),
  //     orderBy("datetime", "asc")
  //   );

  //   const unsubscribeOneOffAlarm = onSnapshot(oneOffAlarmQuery, (snapshot) => {
  //     const alarmsData = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

  //     setAllAlarms((prevAlarms) => {
  //       const updatedAlarms = prevAlarms.filter((alarm) => !alarm.oneOff);

  //       let allAlarms = [...updatedAlarms, ...alarmsData];
  //       console.log(allAlarms, "allAlarms");
  //       // sort by time, ascending
  //       allAlarms.sort((a, b) => {
  //         a.numberTime = parseInt(a.time.split(":")[0]) * 60 + parseInt(a.time.split(":")[1]);
  //         b.numberTime = parseInt(b.time.split(":")[0]) * 60 + parseInt(b.time.split(":")[1]);

  //         return a.numberTime - b.numberTime;
  //       });
  //       return allAlarms;
  //     });
  //   });

  //   // Return a cleanup function to clear all timeouts and the Firestore listener
  //   return () => {
  //     todayAlarm.forEach((alarm) => clearTimeout(alarm.timeoutId));
  //     unsubscribeRepeatAlarm();
  //     unsubscribeOneOffAlarm();
  //   };
  // }, []);

  // useEffect(() => {
  //   // ANCHOR Get today's alarm clock
  //   const repeatAlarmQuery = query(
  //     collection(db, "alarms"),
  //     where("schoolId", "==", schoolId),
  //     where("department", "==", department),
  //     where("repeat", "array-contains", currentDayOfWeek),
  //     where("oneOff", "==", false),
  //     orderBy("time", "asc")
  //   );

  //   const unsubscribeRepeatAlarm = onSnapshot(repeatAlarmQuery, (snapshot) => {
  //     const alarmsData = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  //     console.log("received new data", alarmsData);
  //     setTodayAlarm((prevAlarms) => {
  //       // Update only active alarms in the state
  //       const updatedAlarms = prevAlarms.filter((alarm) => alarm.oneOff);
  //       let allAlarms = [...updatedAlarms, ...alarmsData];
  //       // sort by time, ascending
  //       allAlarms.sort((a, b) => {
  //         a.numberTime = parseInt(a.time.split(":")[0]) * 60 + parseInt(a.time.split(":")[1]);
  //         b.numberTime = parseInt(b.time.split(":")[0]) * 60 + parseInt(b.time.split(":")[1]);

  //         return a.numberTime - b.numberTime;
  //       });
  //       return allAlarms;
  //     });
  //   });
  //   const startOfDay = new Date();
  //   startOfDay.setHours(0, 0, 0, 0);

  //   const endOfDay = new Date();
  //   endOfDay.setHours(23, 59, 59, 999);

  //   const oneOffAlarmQuery = query(
  //     collection(db, "alarms"),
  //     where("schoolId", "==", schoolId),
  //     where("department", "==", department),
  //     where("oneOff", "==", true),
  //     // the where clause should be whether the alarm datetime is today bigger than the 0:00 of today and smaller than the 23:59 of today
  //     where("datetime", ">=", startOfDay),
  //     where("datetime", "<=", endOfDay),
  //     orderBy("datetime", "asc")
  //   );

  //   const unsubscribeOneOffAlarm = onSnapshot(oneOffAlarmQuery, (snapshot) => {
  //     const alarmsData = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

  //     setTodayAlarm((prevAlarms) => {
  //       const updatedAlarms = prevAlarms.filter((alarm) => !alarm.oneOff);
  //       let allAlarms = [...updatedAlarms, ...alarmsData];

  //       allAlarms.sort((a, b) => {
  //         a.numberTime = parseInt(a.time.split(":")[0]) * 60 + parseInt(a.time.split(":")[1]);
  //         b.numberTime = parseInt(b.time.split(":")[0]) * 60 + parseInt(b.time.split(":")[1]);

  //         return a.numberTime - b.numberTime;
  //       });
  //       return allAlarms;
  //     });
  //   });

  //   // Return a cleanup function to clear all timeouts and the Firestore listener
  //   return () => {
  //     todayAlarm.forEach((alarm) => clearTimeout(alarm.timeoutId));
  //     unsubscribeRepeatAlarm();
  //     unsubscribeOneOffAlarm();
  //   };
  // }, []);
  // -- CRUD Operations --
  function handleAdd({ time, description, repeat, oneOff, oneOffDateTime }) {
    const docRef = collection(db, "alarms");
    const baseData = {
      time,
      description,
      active: true,
      createdAt: Timestamp.now(),
      schoolId,
      department,
    };

    if (!oneOff) {
      // recurring
      addDoc(docRef, { ...baseData, oneOff: false, repeat })
        .then(() => console.log("Recurrence alarm added"))
        .catch(console.error);
    } else {
      // oneOff
      addDoc(docRef, { ...baseData, oneOff: true, datetime: new Date(oneOffDateTime) })
        .then(() => console.log("One-off alarm added"))
        .catch(console.error);
    }
  }

  const deleteAlarmInFirestore = (alarm) => {
    // first double check if the user wants to delete the alarm

    console.log("delete alarm");
    // delete it from firebase

    const db = getFirestore(app);
    const alarmsCollection = collection(db, "alarms");
    deleteDoc(doc(alarmsCollection, alarm.id));
  };

  const handleViewTurnedActive = (id) => {
    // setTodayAlarms(todayAlarms.map((alarm) => (alarm.id === id ? { ...alarm, active: !alarm.active } : alarm)));
  };
  const handleEditTimeChange = (event, id) => {
    // setTodayAlarms(todayAlarms.map((alarm) => (alarm.id === id ? { ...alarm, time: event.target.value } : alarm)));
  };
  const playSound = () => {
    const audio = new Audio(soundFile);
    console.log("played");
    audio.play();
  };

  function addMinutesToDate(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
  }

  const handleSnoozeClick = (alarm, snoozeMinutes = 1, alarmTitle = "") => {
    // Snooze for 5 minutes

    handleSetToDoneClick(alarm);

    // Create a new alarm X minutes from now
    const now = new Date();
    const snoozeDate = new Date(now.getTime() + snoozeMinutes * 60000);

    const hh = String(snoozeDate.getHours()).padStart(2, "0");
    const mm = String(snoozeDate.getMinutes()).padStart(2, "0");

    // Insert into Firestore as a new alarm
    addDoc(collection(db, "alarms"), {
      ...alarm,
      title: (alarm.title || alarm.description) + `（貪睡${snoozeMinutes}分）`,
      time: `${hh}:${mm}`,

      active: true,
      oneOff: true,
      datetime: snoozeDate,
      schoolId,
      department,
      doneAt: null,
      createdAt: Timestamp.now(),
    });
  };

  const handleSetToDoneClick = (alarm) => {
    // Mark it doneAt in Firestore so we ignore it from now
    updateDoc(doc(db, "alarms", alarm.id), {
      doneAt: Timestamp.now(),
    });
    // Also remove from activeAlarms
    setActiveAlarms((prev) => prev.filter((a) => a.id !== alarm.id));
  };

  const updateAlarmInFirestore = (newAlarm) => {
    updateDoc(doc(db, "alarms", newAlarm.id), {
      ...newAlarm,
      doneAt: null,
    });
    // db.collection("alarms").doc(newAlarm.id).update(newAlarm);
  };

  const handleLogOff = () => {
    const auth = getAuth(app);
    signOut(auth).then(() => {
      window.location.reload();
    });
  };

  async function createTextToSpeech(text, newAlarm) {
    console.log("createTextToSpeech");

    textToSpeech({ text: text })
      .then((result) => {
        const audio = new Audio(result.data.audioUrl);
        audio.play();
        // save the data to firebase storage and get the url
        const storageRef = ref(storage, `audio/${newAlarm.id}.mp3`);
        console.log(result.data.audioUrl, "base64 ?");
        // remove this part from result.data.audioUrl "data:audio/MP3;base64,"

        // let base64Part = result.data.audioUrl.split(",")[1];
        const task = uploadString(storageRef, result.data.audioUrl, "data_url");
        task.then((snapshot) => {
          console.log("Uploaded a blob or file!");
          getDownloadURL(snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            // update the alarm with the audio url
            const updatedAlarm = { ...newAlarm, audioUrl: downloadURL };
            updateAlarmInFirestore(updatedAlarm);
          });
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <div className="container mt-4">
      {showAlertModal && (
        <AlarmClockModal
          showModal={showAlertModal}
          alarms={activeAlarms}
          setAlarms={setActiveAlarms}
          schoolUsers={schoolUsers}
          handleSnoozeClick={handleSnoozeClick}
          handleSetToDoneClick={handleSetToDoneClick}
          handleClose={() => setShowAlertModal(false)}
          createTextToSpeech={createTextToSpeech}
        />
      )}

      <AddAlarmModal showModal={showAddModal} handleClose={() => setAddShowModal(false)} handleAdd={handleAdd} department={department} />

      <div className="card shadow-sm mb-4">
        <div className="card-body">
          <h4 className="card-title mb-3">鬧鐘管理系統</h4>

          <div className="d-flex justify-content-between align-items-center mb-3">
            <ButtonGroup aria-label="功能選擇">
              {["鬧鐘", "ECB", "門口表", "門口表（當天）", "下週班務"].map((item) => (
                <Button
                  key={item}
                  variant={item === currentMode ? "primary" : "outline-secondary"}
                  onClick={() => {
                    setCurrentMode(item);
                  }}
                  active={item === currentMode}
                  className="px-3"
                >
                  {item}
                </Button>
              ))}
            </ButtonGroup>

            <small className="text-muted">最後更新：2024-11-26</small>
          </div>
        </div>
      </div>

      {currentMode === "鬧鐘" && (
        <div className="card shadow-sm mb-4 border-left-primary">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <i className="bi bi-alarm-fill text-primary fs-1 me-3"></i>
              </div>
              <div className="flex-grow-1">
                {todayAlarms.filter((alarm) => alarm.active).length > 0 ? (
                  (() => {
                    const now = new Date();
                    const currentTime = now.getHours() * 60 + now.getMinutes();

                    // Find the next alarm that hasn't triggered yet
                    const nextAlarm = todayAlarms
                      .filter((alarm) => {
                        const [hours, minutes] = alarm.time.split(":").map(Number);
                        const alarmTime = hours * 60 + minutes;
                        return alarm.active && alarmTime > currentTime;
                      })
                      .sort((a, b) => {
                        const [aHours, aMinutes] = a.time.split(":").map(Number);
                        const [bHours, bMinutes] = b.time.split(":").map(Number);
                        return aHours * 60 + aMinutes - (bHours * 60 + bMinutes);
                      })[0];

                    if (nextAlarm) {
                      return (
                        <>
                          <h5 className="card-title mb-1">下一個鬧鐘</h5>
                          <h3 className="mb-2">{nextAlarm.time}</h3>
                          <p className="card-text mb-0">{nextAlarm.title || nextAlarm.description}</p>
                          <small className="text-muted">
                            {Math.floor((new Date(`${now.toDateString()} ${nextAlarm.time}`).getTime() - now.getTime()) / 60000)} 分鐘後提醒
                          </small>
                        </>
                      );
                    } else {
                      return <p className="mb-0">今天沒有更多鬧鐘了</p>;
                    }
                  })()
                ) : (
                  <p className="mb-0">沒有設定中的鬧鐘</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="mb-4">
        {currentMode === "鬧鐘" ? (
          <AlarmActualList
            // allAlarms={allAlarms}
            currentDayOfWeek={currentDayOfWeek}
            department={department}
            schoolId={schoolId}
            handleViewTurnedActive={handleViewTurnedActive}
            handleEditTimeChange={handleEditTimeChange}
            setAddShowModal={setAddShowModal}
            updateAlarmInFirestore={updateAlarmInFirestore}
            deleteAlarmInFirestore={deleteAlarmInFirestore}
            createTextToSpeech={createTextToSpeech}
          />
        ) : currentMode === "ECB" ? (
          <ECBList />
        ) : currentMode === "下週班務" ? (
          <NextWeekAction schoolId={schoolId} />
        ) : currentMode === "門口表（當天）" ? (
          <CheckAttendanceDay schoolId={schoolId} />
        ) : currentMode === "門口表" ? (
          <CheckAttendance schoolId={schoolId} />
        ) : null}
      </div>

      <div className="d-flex justify-content-end mt-4 mb-5">
        <button type="button" className="btn btn-outline-danger" onClick={() => handleLogOff()}>
          <i className="bi bi-box-arrow-right me-1"></i>
          登出
        </button>
      </div>
    </div>
  );
}

export default AlarmClockList;

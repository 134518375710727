import React, { useState, useEffect } from "react";

import app from "../../firebase";
import { getFirestore, doc, setDoc, updateDoc, addDoc, Timestamp, onSnapshot, query, collection, where } from "firebase/firestore";
import { Modal, Button, Form, FormGroup, FormLabel, FormControl, ButtonGroup } from "react-bootstrap";

function AddAlarmModal({ showModal, handleClose, handleAdd }) {
  const [time, setTime] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [repeat, setRepeat] = useState(["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]);
  const [showModalInternal, setShowModalInternal] = useState(false);
  const [oneOff, setOneOff] = useState(false);
  const [oneOffDateTime, setOneOffDateTime] = useState(null); // new state for date-time value
  function handleRepeatChange(event) {
    const day = event.target.value;
    const isChecked = event.target.checked;
    setRepeat((prevRepeat) => (isChecked ? [...prevRepeat, day] : prevRepeat.filter((d) => d !== day)));
  }

  function handleSubmit(event) {
    event.preventDefault();
    handleAdd({ time, title, description, repeat, oneOff, oneOffDateTime }); // pass dateTime to handleAdd function
    handleClose();
  }

  function handleShow() {
    setShowModalInternal(true);
  }

  function handleHide() {
    setShowModalInternal(false);
  }

  React.useEffect(() => {
    if (showModal) {
      handleShow();
    } else {
      handleHide();
    }
  }, [showModal]);

  function handleOneOffChange(event) {
    setOneOffDateTime(event.target.value);
  }

  const sendTextToChatGPTForPoliteResponse = async (userText) => {
    // What is the best prompt to create a good response ?
    try {
      const response = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer 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`,
        },
        body: JSON.stringify({
          model: "gpt-4o-mini",
          messages: [
            {
              role: "system",
              content: `請用繁體中文將指令改寫成親切、口語化的廣播語句，這廣播是用來體醒櫃檯的老師執行相關的班務、校務。開頭請以「麻煩櫃檯老師 _______ 」並保持在兩到三句話內，禮貌而直接。`,
            },
            {
              role: "user",
              content: `${userText}`,
            },
          ],
        }),
      });
      const data = await response.json();
      console.log(data.choices[0].message.content, "content");
      // setEditedAlarm({ ...editedAlarm, description: data.choices[0].message.content });
      setDescription(data.choices[0].message.content);
    } catch (err) {
      console.log("Error:", err);
    }
  };

  return (
    <Modal show={showModalInternal} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add New Alarm</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <FormGroup>
            <FormLabel htmlFor="time">Time:</FormLabel>
            <FormControl type="time" id="time" required value={time} onChange={(event) => setTime(event.target.value)} />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="title">Title:</FormLabel>
            <FormControl type="text" id="title" required value={title} onChange={(event) => setTitle(event.target.value)} />
          </FormGroup>

          <button
            className="btn"
            style={{ backgroundColor: "pink", color: "#000000" }}
            onClick={() => sendTextToChatGPTForPoliteResponse(`${time} ${title}`)}
          >
            <p> AI改寫</p>
          </button>
          <FormGroup>
            <FormLabel htmlFor="description">Description:</FormLabel>
            <FormControl
              type="text"
              id="description"
              required
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>鬧鐘模式:</FormLabel>
            <FormControl as="select" onChange={(event) => setOneOff(event.target.value === "oneOff")}>
              <option value="repeat">重複</option>
              <option value="oneOff">一次性</option>
            </FormControl>
          </FormGroup>
          {!oneOff && (
            <FormGroup>
              <FormLabel>Repeat:</FormLabel>
              <div className="form-check">
                {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day) => (
                  <div key={day} className="form-check-inline mx-4">
                    <FormControl
                      type="checkbox"
                      className="form-check-input"
                      id={day}
                      value={day}
                      checked={repeat.includes(day)}
                      onChange={handleRepeatChange}
                    />
                    <FormLabel className="form-check-label" htmlFor={day}>
                      {day}
                    </FormLabel>
                  </div>
                ))}
              </div>
            </FormGroup>
          )}
          {oneOff && (
            <FormGroup>
              <FormLabel htmlFor="one-off-date-time">一次性的日期:</FormLabel>
              <FormControl type="date" id="one-off-date-time" required value={oneOffDateTime} onChange={handleOneOffChange} />
            </FormGroup>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={(e) => handleSubmit(e)}>
            Add Alarm
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default AddAlarmModal;
